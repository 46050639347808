@import "../../App/variables.scss";

.Contact {
  // padding-top: 170px;
  // min-height: 800px;
  padding-top: 120px;
  background: $light_brown_trans;

  h1 {
    font-size: 81px;
    font-weight: bold;
    font-style: normal;
    font-family: $h_font_family;
    font-stretch: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: $dark_brown;
    padding-top: 100px;
  }

  .cs_contact {
    // max-width: 810px;
    // left: -65px;
    // top: -100px;
    // position: absolute;
    position: relative;
    min-height: 660px;
    padding-top: 40px;

    img {
      // position: absolute;
      // right: 60px;
      // width: 680px;
      position: absolute;
      left: 0px;
      width: 515px;

      &.bg-img {
        // z-index: 0;
        // width: 670px;
        // height: 700px;
        // position: absolute;
        // left: -200px;
        // top: 50px;
        z-index: 0;
        width: 670px;
        height: 520px;
        position: absolute;
        left: -72px;
        top: 83px;
      }
    }
  }

  .cs_contact_info {
    p {
      font-family: $t_font_family;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $dark_brown;
    }

    a {
      display: block;
      font-family: $t_font_family;
      color: $dark_brown;
      font-size: 18px;
    }
  }

  .cs_socials {
    a {
      display: inline-block;
      margin-right: 40px;

      &.disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .cs_contact-info {
    display: flex;
    max-width: fit-content;
    align-items: center;
    margin-bottom: 8px;
  }

  .cs_profile-boxes {
    margin-top: 60px;
    display: flex;

    .profile-box {
      display: flex;
      align-items: center;

      img {
        height: 150px;
        width: 150px;
        margin-right: 24px;
        border-radius: 50%;

        &.icon {
          height: 16px;
          width: 16px;
          margin-right: 13px;
          border-radius: 0;
        }
      }

      .contact-info {
        display: flex;
        max-width: fit-content;
        align-items: center;
      }
    }
  }

  .mapped-wrapper {
    position: relative;

    .image-wrapper {
      position: absolute;
      z-index: 99;
      left: 60px;
      bottom: 40px;
      border: 4px solid white;
      box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      img {
        width: 240px;
        display: block;
        border-radius: 4px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .Contact {
    .container {
      .cs_contact {
        img {
          &.bg-img {
            right: -141px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $break-large) {
  .Contact {
    h1 {
      font-size: 4rem;
    }

    .cs_about {
      img {
        &.bg-img {
          right: -262px;
        }
      }
    }

    .cs_profile-boxes {
      flex-direction: column;

      .profile-box {
        &:first-child {
          margin-bottom: 30px;
        }
      }
    }

    .cs_socials {
      a {
        display: inline-block;
        margin-right: 20px;
        z-index: 9;
        width: 40px;

        img {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Contact {
    padding-top: 70px;

    h1 {
      padding-top: 0px;
    }

    .cs_contact {
      width: 600px;
      position: relative;
      right: auto;
      padding-top: 80px;

      img {
        left: 0px;
        width: auto;
        height: 550px;

        &.bg-img {
          width: auto;
          height: 550px;
          top: 107px;
          left: 5px;
        }
      }
    }

    .cs_profile-boxes {
      .profile-box {
        flex-direction: column;
        align-items: flex-start;

        img {
          &.portrait {
            margin-bottom: 20px;
          }
        }
      }
    }

    .mapped-wrapper {
      position: relative;

      .image-wrapper {
        position: absolute;
        width: 100%;
        z-index: 99;
        left: 0px;
        top: 10px;
        text-align: center;

        img {
          width: 150px;
          max-width: 40%;
          margin: 5px;
          display: inline-block;
        }
      }
    }
  }
}