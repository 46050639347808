@import url(https://fonts.googleapis.com/css?family=Cabin:400,500,600,700|Open+Sans:400,600,700&display=swap);
* {
  outline: none !important;
  -webkit-appearance: none;
}

body {
  margin: 0;
  padding: 0;
}
body.modal-open {
  padding-right: 0px !important;
}
body.no-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #413d37;
  outline: none;
}
a:hover {
  text-decoration: none;
  color: #655f56;
}

p {
  font-family: "Open Sans", sans-serif;
  color: #655f56;
  font-size: 18px;
  font-weight: normal;
}

.App {
  overflow: hidden;
}
.login-btn {
  margin-left: 20px;
  z-index: 9999;
}

.NavLink {
  position: relative;
}
.NavLink:before {
  content: "";
  width: 0%;
  transition: all 0.2s;
}
.NavLink.active:before {
  content: "";
  width: 140%;
  background-image: linear-gradient(to top, #ff7554, #ffb070);
  height: 3px;
  position: absolute;
  bottom: -4px;
  left: -20%;
  border-radius: 5px;
  transition: all 0.2s;
}
.NavLink.external {
  color: #ff815a;
  padding: 0 20px;
}
.NavLink.external:hover {
  color: #ff815a;
}

.NavButton {
  position: relative;
}
.NavButton.active {
  display: none !important;
}

@media screen and (max-width: 1200px) {
  .NavLink.external {
    padding: 0;
  }
  .basic_btn.navigation.nav-btn {
    width: 135px;
  }
  .basic_btn.bordered.is-small.nav-btn {
    width: 120px;
  }
}
.basic_btn {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  max-width: 160px;
  width: 100%;
  padding: 10px 10px 9px 10px;
  border-radius: 22.5px;
  font-weight: 500;
  display: inline-block;
  border: none;
  outline: none !important;
  box-shadow: none;
  cursor: pointer;
}
.basic_btn:focus {
  outline: none;
}
.basic_btn.fullwidth {
  max-width: 300px;
  margin-top: 15px;
}
.basic_btn.orange {
  color: #fff;
  background-image: linear-gradient(to top, #ff7554, #ffb070);
}
.basic_btn.orange-dark {
  color: #fff;
  background-color: #ff815a;
}
.basic_btn.white {
  color: #413d37;
  background-color: #fff;
}
.basic_btn.black {
  color: #fff;
  background-color: #413d37;
}
.basic_btn.bordered {
  background: none;
  max-width: 200px;
  padding: 6px 10px 6px 10px;
}
.basic_btn.bordered.is-small {
  max-width: 156px;
}
.basic_btn.bordered.light {
  border: solid 3px #fff;
  color: #fff;
}
.basic_btn.bordered.light.thin-border {
  border: solid 1px #fff;
}
.basic_btn.bordered.dark {
  border: solid 3px #413d37;
  color: #413d37;
}
.basic_btn.bordered.dark.thin-border {
  border: solid 1px rgba(65, 61, 55, 0.31);
}
.basic_btn.is-bigger {
  padding: 0px auto;
  border-radius: 28px;
  height: 56px;
}
.basic_btn.is-wide {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0px 40.5px;
}
.basic_btn.is-wider {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0px 60px;
}
.basic_btn.navigation {
  height: 56px;
  width: 176px;
  border-radius: 28px;
}
.basic_btn.full-width {
  width: 100%;
  max-width: 500px;
}
.basic_btn.btn-questionnaire {
  margin-top: 10px !important;
}
.basic_btn.btn-questionnaire.display-none {
  display: none;
}
.basic_btn.mobile-menu {
  margin-bottom: 0px !important;
}
.Header {
  width: 100%;
  position: fixed;
  z-index: 999;
  height: 100px;
  background: #eeeadf;
}
.Header .container {
  max-width: 1440px;
}
.Header .cs_mobile_menu {
  display: none;
}
.Header .cs_menu_trigger {
  display: none;
}
.Header img {
  max-height: 43px;
  margin-top: 25px;
}
.Header nav {
  margin-top: 35px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Header nav div {
  display: flex;
}
.Header nav a {
  margin: 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  /* 
       LOGIN BUTTON

       &:nth-child(6) {
            margin-right: 35px;
        }*/
}
.Header nav a:hover {
  color: #70695f;
}
.Header nav a:first-child {
  margin-left: 0px;
}

@media screen and (max-width: 992px) {
  .Header .cs_menu_trigger {
    display: inline-block;
    background: none;
    border: none;
    margin-top: 8px;
  }
  .Header .cs_mobile_menu {
    position: absolute;
    height: 100vh;
    display: block;
    left: 0px;
    z-index: 99;
    width: 100%;
    background: #eeeadf;
  }
  .Header .cs_mobile_menu nav {
    padding-top: 50px;
    display: block;
  }
  .Header .cs_mobile_menu nav div {
    flex-direction: column;
  }
  .Header .cs_mobile_menu nav a {
    max-width: 180px;
    text-align: center;
    display: block;
    padding: 5px;
    margin: 5px auto;
  }
  .Header .cs_mobile_menu nav a:first-child {
    margin-top: 10px;
  }
  .Header .cs_mobile_menu nav a:last-child {
    margin-bottom: 10px;
  }
  .Header .cs_mobile_menu nav a.btn-questionnaire {
    max-width: 100%;
  }
  .Header .cs_mobile_menu nav a.NavLink.active::before {
    width: 100%;
    left: 0px;
  }
  .Header .cs_mobile_menu nav button {
    margin: 20px auto;
    display: block;
  }
  .Header nav {
    display: none;
  }
}
.tophalf-bg {
  background: linear-gradient(0deg, #ffffff 0%, #eeeadf 100%);
}

.bottomhalf-bg {
  background: linear-gradient(0deg, #ffd8b6 0%, #ffb97b 100%);
}
.AboutProduct {
  padding-top: 60px;
  height: auto;
}
.AboutProduct p.full-width {
  max-width: 920px;
}
.SectionHeadline {
  font-size: 54px;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-weight: bold;
}
.SectionHeadline.x_small {
  font-size: 24px;
}
.SectionHeadline.small {
  font-size: 36px;
}
.SectionHeadline.dark {
  color: #413d37;
}
.SectionHeadline.darker {
  color: #4a463f;
}
.SectionHeadline.light {
  color: #fff;
}
.SectionHeadline.left {
  text-align: left;
}
.SectionHeadline.right {
  text-align: right;
}
.SectionUnderText {
  font-size: 18px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  max-width: 542px;
  margin: 0 auto;
  padding-top: 10px;
}
.SectionUnderText.small {
  font-size: 14px;
}
.SectionUnderText.dark {
  color: #655f56;
}
.SectionUnderText.darker {
  color: #4a463f;
}
.SectionUnderText.light {
  color: #fff;
}
.SectionUnderText.left {
  text-align: left;
}
.SectionUnderText.right {
  text-align: right;
}
.SectionUnderText.bold {
  font-weight: bold;
}
.SectionUnderText.no-padding {
  padding: 0px;
}
.AboutItem .cs_imgwrap {
  position: relative;
  width: 226px;
  height: 201px;
  margin: 0 auto -20px auto;
}
.AboutItem .cs_imgwrap img {
  height: 100%;
  width: 100%;
}
.AboutItem .cs_title {
  padding-top: 5px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: #413d37;
  margin: 0;
}
.AboutItem .cs_text {
  padding-top: 5px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #655f56;
  margin: 0;
}
.AboutVideo {
  margin-bottom: 52px;
}
.AboutVideo .cs_videowrap {
  width: 350px;
  height: 304px;
  margin: 10px auto;
  border-radius: 10px;
  position: relative;
  background-color: black;
}
.AboutVideo .cs_videowrap img {
  height: 100%;
  width: 100%;
}
.AboutVideo .cs_videowrap .player-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 102px;
  width: 102px;
  background: url(/static/media/play-button-normal.a70412ad.svg);
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.AboutVideo .cs_videowrap .player-button:hover {
  background: url(/static/media/play-button-hover.776f162f.svg);
  background-size: cover;
  background-position: center;
  transition: 0.5s ease;
}
.AboutVideo .cs_title {
  padding-top: 5px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: left;
  color: #413d37;
  margin: 0;
}
.AboutVideo .cs_text {
  padding-top: 5px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  color: #655f56;
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .AboutVideo .cs_videowrap {
    width: 300px;
    height: 280px;
  }
}
@media screen and (max-width: 992px) {
  .AboutVideo {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 380px) {
  .AboutVideo .cs_videowrap {
    width: 290px;
    height: 270px;
  }
}
.CSModal.checkbox-modal .modal-body {
  padding: 45px 30px;
}
.CSModal.checkbox-modal p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  max-width: calc(100% - 50px);
  display: inline-block;
}
.CSModal.checkbox-modal p a {
  text-decoration: underline;
  cursor: pointer;
}
.CSModal.checkbox-modal .newslatter-err {
  position: relative;
  margin: 0 0 10px 25px;
}
.CSModal.checkbox-modal input[type=checkbox] {
  display: inline-block;
  position: absolute;
  margin-top: 10px;
  left: -5px;
}
.CSModal.checkbox-modal .modal-content {
  max-width: 553px;
}
.CSModal.success-modal .modal-content {
  max-width: 580px;
}
.CSModal.success-modal svg {
  margin-top: 20px;
}
.CSModal.success-modal p {
  font-family: "Open Sans", sans-serif;
  padding-top: 30px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #413d37;
}
.CSModal .modal-content {
  max-width: 500px;
  margin: 0 auto;
}
.CSModal .modal-body {
  padding: 30px;
  text-align: center;
}
.CSModal .cs_modal_headline {
  color: #413d37;
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
}
.CSModal .cs_modal_headline.news {
  margin-top: 40px;
}
.CSModal .cs_modal_closer {
  background: none;
  border: none;
  padding: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.CSModal .cs_modal_closer img:nth-child(2) {
  display: none;
}
.CSModal .cs_modal_txt {
  padding-top: 25px;
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
.CSModal .cs_modal_txt a {
  color: #ff7554;
}
.CSModal .cs_modal_hireimg {
  width: 80px;
  margin: 20px 0 30px 0;
}
.CSModal .cs_modal_newsimg img {
  width: 100% !important;
  margin: 20px 0 30px 0;
}
.CSModal.cs-video-modal .modal-content {
  background-color: transparent;
  border: none;
  max-width: 700px !important;
  width: 100% !important;
}
.CSModal.cs-video-modal .modal-content .modal-body {
  background-color: transparent;
}
.CSModal.cs-video-modal .modal-content .modal-body .cs_modal_closer img:first-child {
  display: none;
}
.CSModal.cs-video-modal .modal-content .modal-body .cs_modal_closer img:nth-child(2) {
  display: block;
  position: absolute;
  top: -30px;
  left: -30px;
}
@media screen and (max-width: 768px) {
  .CSModal.cs-video-modal .modal-content .modal-body {
    padding: 30px 0px;
  }
}
.CSModal.cs-video-modal .modal-dialog {
  max-width: 100%;
}
.BannerSection {
  padding-top: 120px;
}
.BannerSection h1 {
  padding-top: 85px;
  font-family: "Cabin", sans-serif;
  font-size: 81px;
  font-weight: bold;
  color: #413d37;
}
.BannerSection p {
  margin-bottom: 28px;
  margin-top: 22px;
}
.BannerSection a {
  display: block;
}
.BannerSection a.anchor-link {
  float: right;
  margin-top: 50px;
}
.BannerSection a svg {
  font-size: 26px;
  margin-top: -2px;
  margin-right: 5px;
}
.BannerSection .cs_banner {
  position: relative;
  min-height: 660px;
  padding-top: 70px;
}
.BannerSection .cs_banner img {
  position: absolute;
  left: 0px;
  animation-name: multiple-image-crossfade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  width: 515px;
}
.BannerSection .cs_banner img:nth-of-type(1) {
  animation-delay: 12s;
}
.BannerSection .cs_banner img:nth-of-type(2) {
  animation-delay: 8s;
}
.BannerSection .cs_banner img:nth-of-type(3) {
  animation-delay: 4s;
}
.BannerSection .cs_banner img:nth-of-type(4) {
  animation-delay: 0;
}
.BannerSection .cs_banner img.bg {
  animation: none;
  z-index: 0;
  width: 670px;
  height: 520px;
  position: absolute;
  left: -74px;
  top: 95px;
}
@keyframes multiple-image-crossfade {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 992px) {
  .BannerSection h1 {
    font-size: 4rem;
  }
  .BannerSection .Subscribe .basic_btn {
    max-width: 270px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .BannerSection {
    padding-top: 120px;
  }
  .BannerSection h1 {
    padding-top: 300px;
    font-size: 3rem;
  }
  .BannerSection .cs_banner {
    min-height: 400px;
  }
  .BannerSection .cs_banner img {
    left: 0px;
    width: auto;
    height: 550px;
  }
  .BannerSection .cs_banner img.bg {
    width: auto;
    height: 550px;
    left: 7px;
  }
}
.News {
  padding-bottom: 300px;
}
.News .news_space_placeholder {
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
}
.News .blogs_space_placeholder {
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
}
.News .blog_headline {
  margin-top: 120px;
}

@media screen and (max-width: 768px) {
  .News {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .News .blog_headline {
    margin-top: 70px;
  }
}
.BlogsItem {
  background: #fff;
  border-radius: 6px;
}
.BlogsItem .cs_imgwrap {
  height: 177px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  text-align: center;
}
.BlogsItem .cs_imgwrap img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}
.BlogsItem .cs_contentwrap {
  padding: 26px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 195px;
}
.BlogsItem .cs_contentwrap .cs_title {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #413d37;
  margin-bottom: 2px;
}
.BlogsItem .cs_contentwrap .cs_text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
  margin-bottom: 10px;
}
.BlogsItem .cs_contentwrap .cs_date {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}
.NewsItem {
  background: #fff;
  border-radius: 6px;
}
.NewsItem .cs_imgwrap {
  height: 177px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  text-align: center;
}
.NewsItem .cs_imgwrap img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}
.NewsItem .cs_contentwrap {
  padding: 26px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 195px;
}
.NewsItem .cs_contentwrap .cs_title {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #413d37;
  margin-bottom: 2px;
}
.NewsItem .cs_contentwrap .cs_text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
  margin-bottom: 10px;
}
.NewsItem .cs_contentwrap .cs_date {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}
.Newsletter {
  background-color: #fff;
  padding-bottom: 120px;
  position: relative;
}
.Newsletter .cs_wave {
  width: 100%;
  position: absolute;
  top: 0;
  margin-top: -200px;
}
.Newsletter .z-maxed {
  z-index: 99;
}
.Newsletter small {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  max-width: 568px;
  width: 100%;
  display: block;
  margin: 0 auto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
.Newsletter small a {
  color: #fff;
  text-decoration: underline;
}
.Newsletter .newsletter_big_width {
  max-width: 800px;
}

@media screen and (max-width: 992px) {
  .Newsletter .cs_wave {
    margin-top: -120px;
  }
}
@media screen and (max-width: 768px) {
  .Newsletter {
    padding-bottom: 55px;
    padding-top: 45px;
  }
  .Newsletter .cs_wave {
    margin-top: -50px;
  }
}
.Questions {
  height: auto;
  position: relative;
  padding-bottom: 120px;
  margin-top: -50px;
}
.Questions h2 {
  margin-bottom: 60px;
}
.Questions .toggle-buttons {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 50px;
}
.Questions img {
  max-height: 570px;
}

@media screen and (max-width: 1920px) {
  .Questions {
    margin-top: -100px;
  }
}
@media screen and (max-width: 992px) {
  .Questions {
    margin-top: -150px;
  }
}
@media screen and (max-width: 768px) {
  .Questions {
    margin-top: -200px;
    padding-bottom: 0px;
  }
}
@charset "UTF-8";
.accordion {
  width: 100%;
}
.accordion.left {
  margin-right: 30px;
}
.accordion .question-text {
  text-align: start !important;
}
.accordion .card {
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid #dcd9d6 !important;
  border-radius: 8px !important;
}
.accordion .card .card-header {
  background-color: #fff;
  height: 84px;
  display: flex;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: none;
  border-bottom-right-radius: 8px;
}
.accordion .card .card-header button {
  color: #413d37;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion .card .collapse.show .card-body {
  background-color: #fff;
  border-top: none;
}

@media screen and (max-width: 992px) {
  .accordion .question-text {
    text-align: center !important;
    margin-bottom: 20px;
  }
  .accordion .card .card-header {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px;
  }
  .accordion .card .card-header button.questions {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media screen and (max-width: 400px) {
  .accordion .card .card-header {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.accordion > .card:first-of-type {
  border-bottom: 1px solid #dcd9d6;
}

.faqs {
  width: 100%;
  margin: apí0 auto;
  display: flex;
  flex-wrap: wrap;
  font-family: "Cabin", sans-serif;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dcd9d6;
  min-height: 84px;
  height: 84px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(50% - 30px);
}

.faqs .faq .faq-question {
  position: relative;
  padding-left: 25px;
  transition: all 0.4s ease;
  color: #413d37;
  font-weight: 700;
}

.faqs .faq .faq-question span {
  margin-right: 30px;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 14px;
  height: 8px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAAAXNSR0IArs4c6QAAAS5JREFUGBljYACCtLQ0Vlc7c1UQmxBwdLTWBqlh/P//P6OzveWS/wz/vZgYWfz2Hjx6GJtmkOF3rl+c+5+BMYSJicmZKSwsjJXh/382hv8MAv/+/93lZG8ZiK7Rz8+a986NS1v/MzDEMjAy/GVg+MvDfO3atb/xSalrHz64Kwo0wJKRgSFESV725f2HT86CDPBycJD49v33HqDLrBkZGV8y/mdw3Xfo5FGgOgRwsrOoASpoBokAFTWwMDGu/P3v/3aggQpAhbdYGNk9dh06dB8sj9AGYTnZWqQAnTMDaAAzUPNPIM3OyMhwnJGVx3fv3r1vYeqZYAwYve/wiTlAvwQB7fwO0gQ0ZKOwOJMzsiaQWmaYBmT6wcMnN1WVZQ4AA/y9iLhc6urVO38hy4PYAHVZdGDRgjZUAAAAAElFTkSuQmCC);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.faqs .faq .faq-answer {
  opacity: 0;
  padding: 0 25px;
  max-height: 0;
  overflow-y: hidden;
  color: #655f56;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
}

.faqs .faq.open {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.faqs .faq .faq-question :first-child {
  display: flex;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

@media (max-width: 1024px) {
  .faqs .faq {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .faqs .faq .faq-question :first-child {
    max-width: 400px;
    display: flex;
  }
}
@media (max-width: 520px) {
  .faqs .faq .faq-question :first-child {
    max-width: 300px;
  }
  .faqs .faq {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
}
@media (max-width: 430px) {
  .faqs .faq .faq-question :first-child {
    max-width: 180px;
    display: flex;
  }
}
.Solution {
  padding-top: 120px;
  height: auto;
  padding-bottom: 260px;
  position: relative;
}
.Solution p.full-width {
  max-width: 920px;
}
.Solution .toggle-buttons {
  text-align: center;
  margin: 60px auto 0px auto;
  background-color: #eeeadf;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 27.5px;
}
.Solution .toggle-buttons button {
  height: 56px;
  width: 270px;
  border-radius: 27.5px;
  font-size: 18px;
  font-weight: bold;
  background-color: transparent;
  color: #413d37;
  border: none;
}
.Solution .toggle-buttons .is-active {
  background-color: #ff8b5f;
  color: #fff;
}
.Solution .img-right {
  height: 620px;
  margin-bottom: 95px;
}
.Solution .accordion {
  margin: 140px 0 auto 0;
}
.Solution .cs_wave {
  width: 100%;
  position: absolute;
  max-height: 100%;
}

@media screen and (max-width: 992px) {
  .Solution .img-right {
    width: 100%;
    height: auto !important;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .Solution .img-right {
    max-height: 475px !important;
  }
  .Solution .toggle-buttons {
    width: auto;
    margin-bottom: 50px;
  }
  .Solution .accordion {
    margin: auto 0;
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 420px) {
  .Solution .img-right {
    max-height: 320px !important;
  }
}
.solutions {
  width: 100%;
  margin: 140px 0 auto;
  font-family: "Cabin", sans-serif;
}
@media screen and (max-width: 768px) {
  .solutions {
    margin-top: 0px;
  }
}

.solutions .solution {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dcd9d6;
  min-height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solutions .solution .solution-question {
  position: relative;
  padding-left: 25px;
  transition: all 0.4s ease;
  color: #413d37;
  font-weight: 700;
}

.solutions .solution .solution-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 14px;
  height: 8px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAAAXNSR0IArs4c6QAAAS5JREFUGBljYACCtLQ0Vlc7c1UQmxBwdLTWBqlh/P//P6OzveWS/wz/vZgYWfz2Hjx6GJtmkOF3rl+c+5+BMYSJicmZKSwsjJXh/382hv8MAv/+/93lZG8ZiK7Rz8+a986NS1v/MzDEMjAy/GVg+MvDfO3atb/xSalrHz64Kwo0wJKRgSFESV725f2HT86CDPBycJD49v33HqDLrBkZGV8y/mdw3Xfo5FGgOgRwsrOoASpoBokAFTWwMDGu/P3v/3aggQpAhbdYGNk9dh06dB8sj9AGYTnZWqQAnTMDaAAzUPNPIM3OyMhwnJGVx3fv3r1vYeqZYAwYve/wiTlAvwQB7fwO0gQ0ZKOwOJMzsiaQWmaYBmT6wcMnN1WVZQ4AA/y9iLhc6urVO38hy4PYAHVZdGDRgjZUAAAAAElFTkSuQmCC);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.solutions .solution .solution-answer {
  opacity: 0;
  padding: 0 25px;
  max-height: 0;
  overflow-y: hidden;
  color: #655f56;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
}

.solutions .solution.open .solution-question {
  margin-bottom: 15px;
}

.solutions .solution.open .solution-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.solutions .solution.open .solution-answer {
  max-height: 1000px;
  opacity: 1;
}

@media (max-width: 500px) {
  .solutions .solution {
    padding: 0;
  }
}
@media (max-width: 520px) {
  .solutions .solution .solution-question :first-child {
    max-width: 300px;
    display: flex;
  }
}
@media (max-width: 450px) {
  .solutions .solution .solution-question :first-child {
    max-width: 200px;
  }
}
@media (max-width: 320px) {
  .solutions .solution .solution-question :first-child {
    max-width: 180px;
  }
}
.tophalf-bg {
  background: linear-gradient(0deg, #ffffff 0%, #eeeadf 100%);
}

.bottomhalf-bg {
  background: linear-gradient(0deg, #ffd8b6 0%, #ffb97b 100%);
}
.AboutUs {
  padding-top: 120px;
}
.AboutUs .container .cs_about {
  position: relative;
  min-height: 660px;
  padding-top: 30px;
}
.AboutUs .container .cs_about img {
  position: absolute;
  right: 0px;
  width: 515px;
}
.AboutUs .container .cs_about img.bg-img {
  z-index: 0;
  width: 670px;
  height: 520px;
  position: absolute;
  left: -52px;
  top: 71px;
}
.AboutUs .cs_quote {
  background-image: url(/static/media/uvodzovky.07a2c1db.svg);
  background-repeat: no-repeat;
  background-position: right;
  margin-top: 40px;
}
.AboutUs .cs_quote p {
  font-size: 31px;
  color: #8c8478;
  font-family: "Cabin", sans-serif;
  font-style: italic;
}
.AboutUs .cs_quote p .bold-text {
  font-size: 34px;
  color: #544f48;
  font-weight: 700;
}
.AboutUs h2 {
  padding-top: 80px;
}
.AboutUs p {
  max-width: 450px;
  margin-left: 0px;
}
.AboutUs .cs_isohead {
  font-size: 24px;
  font-weight: bold;
  margin: 26px 0 16px 0;
  color: #413d37;
}
.AboutUs a.cs_cert {
  padding: 23px;
  max-width: calc(50% - 30px);
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  background: #eeeadf;
  font-weight: bold;
  font-size: 18px;
  color: #413d37;
}
.AboutUs a:first-of-type {
  margin-right: 30px;
}

@media screen and (max-width: 1200px) {
  .AboutUs .container .cs_about img.bg-img {
    left: -141px;
  }
}
@media screen and (max-width: 992px) {
  .AboutUs .container .cs_about img.bg-img {
    left: -262px;
  }
}
@media screen and (max-width: 768px) {
  .AboutUs {
    min-height: auto;
  }
  .AboutUs h2 {
    padding-top: 100px;
  }
  .AboutUs .container .cs_about {
    min-height: 400px;
    width: 600px;
    position: relative;
    right: auto;
  }
  .AboutUs .container .cs_about img {
    left: 0px;
    width: auto;
    height: 550px;
  }
  .AboutUs .container .cs_about img.bg-img {
    width: auto;
    height: 550px;
    left: 0px;
    top: 60px;
  }
  .AboutUs .cs_quote {
    background-image: url(/static/media/uvodzovky.07a2c1db.svg);
    background-repeat: no-repeat;
    background-position: right;
    margin-top: 250px;
  }
}
.Partners {
  background: linear-gradient(0deg, #ffd8b6 0%, #ffb97b 100%);
  position: relative;
  padding-bottom: 380px;
}
.Partners .PartnersItem {
  padding-top: 20px;
}
.Partners .PartnersItem img {
  max-width: 200px;
  max-height: 80px;
}

@media screen and (max-width: 2560px) {
  .Partners {
    padding-top: 0;
    padding-bottom: 120px;
  }
}
@media screen and (max-width: 768px) {
  .Partners {
    padding-bottom: 100px;
  }
}
.Team {
  padding-bottom: 300px;
  position: relative;
}
.Team p.full-width {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.Team .cs_wave {
  width: 100%;
  position: absolute;
  bottom: -300px;
}

@media screen and (max-width: 1920px) {
  .Team .cs_wave {
    bottom: -180px;
  }
}
@media screen and (max-width: 1200px) {
  .Team {
    padding-bottom: 200px;
  }
  .Team .cs_wave {
    bottom: -150px;
  }
}
@media screen and (max-width: 992px) {
  .Team {
    padding-bottom: 150px;
  }
  .Team .cs_wave {
    bottom: -100px;
  }
}
@media screen and (max-width: 768px) {
  .Team .cs_wave {
    bottom: -50px;
  }
}
.TeamItem .cs_imgwrap {
  width: 140px;
  height: 140px;
  margin: 10px auto;
  border-radius: 120px;
  position: relative;
}
.TeamItem .cs_imgwrap img:first-child {
  position: absolute;
  left: -26px;
  top: -16px;
  width: 210px;
  z-index: 0;
}
.TeamItem .cs_imgwrap img:nth-child(2) {
  position: relative;
  margin-top: 0px;
  max-width: 100%;
  border-radius: 50%;
}
.TeamItem .cs_title {
  padding-top: 5px;
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: #413d37;
  margin: 0;
}
.TeamItem .cs_text {
  padding-top: 5px;
  display: block;
  max-width: 255px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #655f56;
}
.TeamItem .cs_linked {
  display: block;
  margin-top: 10px;
}
.Newest {
  padding-bottom: 150px;
  padding-top: 50px;
  background: #eeeadf;
  position: relative;
}
.Newest .cs_wave {
  position: absolute;
  bottom: -5px;
  width: 100%;
}
.NewestItem {
  background: #fff;
  border-radius: 6px;
}
.NewestItem .cs_imgwrap {
  height: 305px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  text-align: center;
}
.NewestItem .cs_imgwrap img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}
.NewestItem .cs_contentwrap {
  padding: 17px 20px 15px 20px;
}
.NewestItem .cs_contentwrap .cs_title {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #413d37;
  margin-bottom: 2px;
}
.NewestItem .cs_contentwrap .cs_text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
  margin-bottom: 10px;
}
.NewestItem .cs_contentwrap .cs_date {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}

@media screen and (max-width: 992px) {
  .NewestItem .cs_imgwrap {
    height: 205px;
  }
}
.TopRead {
  padding: 0 0 200px 0;
}
.TopRead .cs_newsletter_head {
  font-family: "Cabin", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #413d37;
  margin-left: 115px;
}
.TopRead .cs_newsletter_small {
  padding-top: 30px;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}
.TopRead .cs_newsletter_small a {
  text-decoration: underline;
}
.TopRead .cs_newsletter {
  position: relative;
}
.TopRead .cs_newsletter img {
  position: absolute;
  left: 5px;
  top: -40px;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .TopRead .cs_newsletter {
    position: relative;
    margin-top: 100px;
  }
}
.ReadItem {
  background: #fff;
  border-radius: 6px;
}
.ReadItem .cs_imgwrap {
  height: 180px;
  width: 180px;
  border-top-left-radius: 4px;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}
.ReadItem .cs_imgwrap img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}
.ReadItem .cs_contentwrap {
  padding: 0px 20px 15px 20px;
  width: calc(100% - 180px);
  max-height: 180px;
  display: inline-block;
}
.ReadItem .cs_contentwrap .cs_title {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #413d37;
  margin-bottom: 2px;
}
.ReadItem .cs_contentwrap .cs_text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  height: 111px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
  margin-bottom: 10px;
}
.ReadItem .cs_contentwrap .cs_date {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}

@media screen and (max-width: 768px) {
  .ReadItem .cs_imgwrap {
    display: block;
    width: 100%;
  }
  .ReadItem .cs_contentwrap {
    padding: 20px 0px 30px 0px;
    display: block;
    width: 100%;
    max-height: inherit;
  }
  .ReadItem .cs_contentwrap .cs_text {
    height: auto;
  }
}
.Post {
  padding-bottom: 120px;
  padding-top: 180px;
}
.Post .cs_bg_vawe {
  height: 480px;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 80px;
  background: #eeeadf;
}
.Post .cs_bg_vawe img {
  width: 100%;
  position: absolute;
  bottom: -3px;
}
.Post .SocialMediaShareButton {
  display: inline-block;
}
.Post .SocialMediaShareButton:focus {
  outline: none;
}
.Post .SocialMediaShareCount {
  display: inline-block;
  position: relative;
  outline: none;
}
.Post #share-button {
  outline: none;
  border-radius: 3px;
  font-weight: 600;
  padding: 0px 8px;
  display: inline-block;
}
.Post #share-button.fb {
  background: #3b5998;
}
.Post #share-button.fb:hover {
  cursor: pointer;
  background: #213a6f;
}
.Post #share-button.fb svg {
  width: 15px;
  fill: white;
  margin-top: -3px;
  vertical-align: middle;
  border-radius: 2px;
}
.Post #share-button.linked {
  background: #0073b1;
  margin-right: 50px;
}
.Post #share-button.linked:hover {
  cursor: pointer;
  background: #01679e;
}
.Post #share-button.linked svg {
  width: 15px;
  fill: white;
  margin-top: -5px;
  vertical-align: middle;
  border-radius: 2px;
}
.Post #share-button span {
  vertical-align: middle;
  color: white;
  font-size: 13px;
  padding: 0 3px 0px 7px;
}
.Post .BlogsItem .cs_imgwrap {
  height: 196px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.Post .BlogsItem .cs_contentwrap {
  padding: 17px 20px 0px 0px;
}
.Post .BlogsItem .cs_contentwrap .cs_text {
  display: none !important;
}
.Post .cs_post_body {
  max-width: 730px;
  margin: 0 auto;
  position: relative;
}
.Post .cs_post_body .cs_count {
  height: 20px;
  width: auto;
  color: #4e4e4e;
  font-family: Arial;
  font-size: 11px;
  margin-left: 10px;
  margin-bottom: -5px;
  font-weight: bold;
  border-radius: 4px;
  line-height: 12px;
  padding: 3px 7px 3px 7px;
  border: 1px solid #d8d8d8;
  text-align: center;
  position: relative;
  float: left;
}
.Post .cs_post_body .cs_count:after {
  position: absolute;
  width: 7px;
  height: 7px;
  border-top: 0px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 0px solid #d8d8d8;
  top: 100%;
  left: 0%;
  margin-left: -4px;
  content: "";
  transform: rotate(134deg);
  margin-top: -13px;
  background: white;
}
.Post .cs_post_headline {
  color: #413d37;
  font-family: "Cabin", sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
}
.Post .cs_post_content {
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 27px;
  padding-top: 30px;
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.Post .cs_post_img {
  object-fit: cover;
}
.Post .cs_post_img img {
  width: 100%;
  margin-top: 30px;
}
.Post .cs_post_small {
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
}
.Post .cs_post_btn {
  position: absolute;
  left: -180px;
  top: 8px;
  max-width: 160px;
  padding-bottom: 4px;
}
.Post .cs_post_btn svg {
  font-size: 30px;
  margin-top: -3px;
  margin-left: -6px;
}

@media screen and (max-width: 1200px) {
  .Post .cs_post_body .cs_post_btn {
    position: relative;
    left: auto;
    top: 8px;
    max-width: 160px;
    padding-bottom: 4px;
    margin-bottom: 30px;
  }
  .Post .cs_post_body .cs_post_btn svg {
    font-size: 30px;
    margin-top: -3px;
    margin-left: -6px;
  }
}
@media screen and (max-width: 768px) {
  .Post {
    padding-bottom: 120px;
    padding-top: 80px;
  }
  .Post .cs_bg_vawe {
    height: 330px;
  }
}
.Contact {
  padding-top: 120px;
  background: linear-gradient(to bottom, #eeeadf, #fff);
}
.Contact h1 {
  font-size: 81px;
  font-weight: bold;
  font-style: normal;
  font-family: "Cabin", sans-serif;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #413d37;
  padding-top: 100px;
}
.Contact .cs_contact {
  position: relative;
  min-height: 660px;
  padding-top: 40px;
}
.Contact .cs_contact img {
  position: absolute;
  left: 0px;
  width: 515px;
}
.Contact .cs_contact img.bg-img {
  z-index: 0;
  width: 670px;
  height: 520px;
  position: absolute;
  left: -72px;
  top: 83px;
}
.Contact .cs_contact_info p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #413d37;
}
.Contact .cs_contact_info a {
  display: block;
  font-family: "Open Sans", sans-serif;
  color: #413d37;
  font-size: 18px;
}
.Contact .cs_socials a {
  display: inline-block;
  margin-right: 40px;
}
.Contact .cs_socials a.disabled {
  pointer-events: none;
  cursor: default;
}
.Contact .cs_contact-info {
  display: flex;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  align-items: center;
  margin-bottom: 8px;
}
.Contact .cs_profile-boxes {
  margin-top: 60px;
  display: flex;
}
.Contact .cs_profile-boxes .profile-box {
  display: flex;
  align-items: center;
}
.Contact .cs_profile-boxes .profile-box img {
  height: 150px;
  width: 150px;
  margin-right: 24px;
  border-radius: 50%;
}
.Contact .cs_profile-boxes .profile-box img.icon {
  height: 16px;
  width: 16px;
  margin-right: 13px;
  border-radius: 0;
}
.Contact .cs_profile-boxes .profile-box .contact-info {
  display: flex;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  align-items: center;
}
.Contact .mapped-wrapper {
  position: relative;
}
.Contact .mapped-wrapper .image-wrapper {
  position: absolute;
  z-index: 99;
  left: 60px;
  bottom: 40px;
  border: 4px solid white;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.Contact .mapped-wrapper .image-wrapper img {
  width: 240px;
  display: block;
  border-radius: 4px;
}

@media screen and (max-width: 1200px) {
  .Contact .container .cs_contact img.bg-img {
    right: -141px;
  }
}
@media screen and (max-width: 992px) {
  .Contact h1 {
    font-size: 4rem;
  }
  .Contact .cs_about img.bg-img {
    right: -262px;
  }
  .Contact .cs_profile-boxes {
    flex-direction: column;
  }
  .Contact .cs_profile-boxes .profile-box:first-child {
    margin-bottom: 30px;
  }
  .Contact .cs_socials a {
    display: inline-block;
    margin-right: 20px;
    z-index: 9;
    width: 40px;
  }
  .Contact .cs_socials a img {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .Contact {
    padding-top: 70px;
  }
  .Contact h1 {
    padding-top: 0px;
  }
  .Contact .cs_contact {
    width: 600px;
    position: relative;
    right: auto;
    padding-top: 80px;
  }
  .Contact .cs_contact img {
    left: 0px;
    width: auto;
    height: 550px;
  }
  .Contact .cs_contact img.bg-img {
    width: auto;
    height: 550px;
    top: 107px;
    left: 5px;
  }
  .Contact .cs_profile-boxes .profile-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .Contact .cs_profile-boxes .profile-box img.portrait {
    margin-bottom: 20px;
  }
  .Contact .mapped-wrapper {
    position: relative;
  }
  .Contact .mapped-wrapper .image-wrapper {
    position: absolute;
    width: 100%;
    z-index: 99;
    left: 0px;
    top: 10px;
    text-align: center;
  }
  .Contact .mapped-wrapper .image-wrapper img {
    width: 150px;
    max-width: 40%;
    margin: 5px;
    display: inline-block;
  }
}
.Questionnaire {
  padding-top: 170px;
  min-height: 800px;
  background: linear-gradient(to bottom, #eeeadf, #fff);
  padding-bottom: 100px;
}
.Questionnaire h1 {
  font-size: 54px;
  font-weight: bold;
  font-style: normal;
  font-family: "Cabin", sans-serif;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #413d37;
  padding-top: 0px;
}
.Questionnaire .cs_top_img {
  max-width: 700px;
  left: 0px;
  top: -50px;
  position: absolute;
}
.Questionnaire .cs_contact_info p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #413d37;
}
.Questionnaire .cs_contact_info a {
  display: block;
  font-family: "Open Sans", sans-serif;
  color: #413d37;
  font-size: 18px;
}
.Questionnaire .cs_socials a {
  display: inline-block;
  margin-right: 40px;
}
.Questionnaire .cs_socials a.disabled {
  pointer-events: none;
  cursor: default;
}
.Questionnaire .mapped-wrapper {
  position: relative;
}
.Questionnaire .mapped-wrapper .image-wrapper {
  position: absolute;
  z-index: 1000;
  left: 60px;
  top: 60px;
}
.Questionnaire .mapped-wrapper .image-wrapper img {
  width: 240px;
  display: block;
}
.Questionnaire .question-area {
  margin-top: 140px;
}

@media screen and (max-width: 992px) {
  .Questionnaire h1 {
    font-size: 4rem;
  }
  .Questionnaire .cs_top_img {
    left: 0px;
    top: -50px;
  }
  .Questionnaire .cs_socials a {
    display: inline-block;
    margin-right: 20px;
    z-index: 9;
    width: 40px;
  }
  .Questionnaire .cs_socials a img {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .Questionnaire {
    padding-top: 70px;
  }
  .Questionnaire h1 {
    padding-top: 0px;
  }
  .Questionnaire .cs_top_img {
    max-height: 450px;
    margin-left: -30px;
    top: 20px;
    position: relative;
    margin-bottom: 20px;
  }
  .Questionnaire .mapped-wrapper {
    position: relative;
  }
  .Questionnaire .mapped-wrapper .image-wrapper {
    position: absolute;
    width: 100%;
    z-index: 1000;
    left: 0px;
    top: 10px;
    text-align: center;
  }
  .Questionnaire .mapped-wrapper .image-wrapper img {
    width: 150px;
    max-width: 40%;
    margin: 5px;
    display: inline-block;
  }
}
.FormSuccess {
  padding-top: 0px;
  min-height: 900px;
}
.FormSuccess .cs_success {
  position: relative;
  min-height: 660px;
  padding-top: 50px;
}
.FormSuccess .cs_success img {
  position: absolute;
  right: -75px;
  width: 680px;
}
.FormSuccess .cs_success img.bg {
  z-index: 0;
  width: 670px;
  height: 700px;
  position: absolute;
  left: -200px;
  top: 190px;
}
.FormSuccess h2 {
  padding-top: 200px;
}
.FormSuccess .cs_isohead {
  font-size: 24px;
  font-weight: bold;
  margin: 26px 0 16px 0;
  color: #413d37;
}
.FormSuccess a.cs_cert {
  padding: 23px;
  max-width: calc(50% - 30px);
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  background: #eeeadf;
  font-weight: bold;
  font-size: 18px;
  color: #413d37;
}
.FormSuccess a:first-of-type {
  margin-right: 30px;
}

@media screen and (max-width: 992px) {
  .FormSuccess a.cs_cert {
    max-width: 45%;
    padding: 10px;
  }
  .FormSuccess .cs_success {
    min-height: 650px;
  }
  .FormSuccess .cs_success img.bg {
    width: auto;
    height: 700px;
    left: -343px;
    top: 158px;
  }
  .FormSuccess .cs_quote {
    margin-top: 220px;
  }
}
@media screen and (max-width: 768px) {
  .FormSuccess {
    min-height: auto;
  }
  .FormSuccess h2 {
    padding-top: 100px;
  }
  .FormSuccess .cs_success {
    min-height: 400px;
    margin-bottom: 250px;
  }
  .FormSuccess .cs_success img {
    left: -75px;
    width: auto;
    height: 700px;
  }
  .FormSuccess .cs_success img.bg {
    width: auto;
    height: 350px;
    left: 20px;
    top: 190px;
  }
}
.tophalf-bg {
  background: linear-gradient(0deg, #ffffff 0%, #eeeadf 100%);
}

.bottomhalf-bg {
  background: linear-gradient(0deg, #ffd8b6 0%, #ffb97b 100%);
}
.Footer {
  background-image: linear-gradient(to bottom, #585149, #413d37);
  position: relative;
  padding-top: 80px;
  padding-bottom: 105px;
}
.Footer .cs_logo {
  max-height: 72px;
  margin-top: 25px;
}
.Footer small {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ada79f;
  margin-top: -56px;
  display: block;
}
.Footer nav {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.Footer nav div {
  display: flex;
}
.Footer nav a {
  margin: 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  /* 
       LOGIN BUTTON

       &:nth-child(6) {
            margin-right: 35px;
        }*/
}
.Footer nav a:first-child {
  margin-left: 0px;
}
.Footer nav:last-child {
  align-items: flex-end;
}
.Footer nav button {
  margin-top: 75px;
  width: 160px;
}
.Footer .cs_wave {
  position: absolute;
  margin-top: -4%;
  top: 0px;
  width: 100%;
  margin-left: -5%;
}

@media screen and (max-width: 992px) {
  .Footer small {
    padding-top: 10px;
    margin-top: 48px;
    text-align: center;
  }
  .Footer .basic_btn {
    display: block;
    margin: 20px auto 0 auto;
  }
  .Footer .cs_logo {
    display: block;
    margin: 0 auto;
  }
  .Footer nav {
    margin-top: 10px;
  }
  .Footer nav div {
    flex-direction: column;
  }
  .Footer nav a {
    margin: 5px auto;
    text-transform: uppercase;
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    max-width: 120px;
  }
  .Footer nav a:first-child {
    margin-top: 10px;
    margin-left: auto;
  }
  .Footer nav a:nth-child(6) {
    margin-right: auto;
  }
  .Footer nav a::last-child {
    margin-bottom: 10px;
  }
  .Footer nav:last-child {
    align-items: center;
  }
}
.Privacy {
  background: #eeeadf;
  padding-top: 120px;
  padding-bottom: 120px;
}
.Privacy .privacy-content {
  max-width: 920px;
  margin: 0 auto;
}
.Privacy .privacy-content a {
  color: #ff672e;
}
.Privacy .privacy-content a:hover {
  text-decoration: underline;
}
.Privacy .privacy-content ul {
  font-family: "Open Sans", sans-serif;
  color: #655f56;
  font-size: 18px;
  font-weight: normal;
}
.Privacy .privacy-content ul li {
  margin-top: 8px;
}
.Privacy .privacy-content .mid-list {
  padding-top: 18px;
}

@media screen and (max-width: 768px) {
  .Privacy {
    padding-top: 70px;
  }
}
