.Contact {
  padding-top: 120px;
  background: linear-gradient(to bottom, #eeeadf, #fff);
}
.Contact h1 {
  font-size: 81px;
  font-weight: bold;
  font-style: normal;
  font-family: "Cabin", sans-serif;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #413d37;
  padding-top: 100px;
}
.Contact .cs_contact {
  position: relative;
  min-height: 660px;
  padding-top: 40px;
}
.Contact .cs_contact img {
  position: absolute;
  left: 0px;
  width: 515px;
}
.Contact .cs_contact img.bg-img {
  z-index: 0;
  width: 670px;
  height: 520px;
  position: absolute;
  left: -72px;
  top: 83px;
}
.Contact .cs_contact_info p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #413d37;
}
.Contact .cs_contact_info a {
  display: block;
  font-family: "Open Sans", sans-serif;
  color: #413d37;
  font-size: 18px;
}
.Contact .cs_socials a {
  display: inline-block;
  margin-right: 40px;
}
.Contact .cs_socials a.disabled {
  pointer-events: none;
  cursor: default;
}
.Contact .cs_contact-info {
  display: flex;
  max-width: fit-content;
  align-items: center;
  margin-bottom: 8px;
}
.Contact .cs_profile-boxes {
  margin-top: 60px;
  display: flex;
}
.Contact .cs_profile-boxes .profile-box {
  display: flex;
  align-items: center;
}
.Contact .cs_profile-boxes .profile-box img {
  height: 150px;
  width: 150px;
  margin-right: 24px;
  border-radius: 50%;
}
.Contact .cs_profile-boxes .profile-box img.icon {
  height: 16px;
  width: 16px;
  margin-right: 13px;
  border-radius: 0;
}
.Contact .cs_profile-boxes .profile-box .contact-info {
  display: flex;
  max-width: fit-content;
  align-items: center;
}
.Contact .mapped-wrapper {
  position: relative;
}
.Contact .mapped-wrapper .image-wrapper {
  position: absolute;
  z-index: 99;
  left: 60px;
  bottom: 40px;
  border: 4px solid white;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.Contact .mapped-wrapper .image-wrapper img {
  width: 240px;
  display: block;
  border-radius: 4px;
}

@media screen and (max-width: 1200px) {
  .Contact .container .cs_contact img.bg-img {
    right: -141px;
  }
}
@media screen and (max-width: 992px) {
  .Contact h1 {
    font-size: 4rem;
  }
  .Contact .cs_about img.bg-img {
    right: -262px;
  }
  .Contact .cs_profile-boxes {
    flex-direction: column;
  }
  .Contact .cs_profile-boxes .profile-box:first-child {
    margin-bottom: 30px;
  }
  .Contact .cs_socials a {
    display: inline-block;
    margin-right: 20px;
    z-index: 9;
    width: 40px;
  }
  .Contact .cs_socials a img {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .Contact {
    padding-top: 70px;
  }
  .Contact h1 {
    padding-top: 0px;
  }
  .Contact .cs_contact {
    width: 600px;
    position: relative;
    right: auto;
    padding-top: 80px;
  }
  .Contact .cs_contact img {
    left: 0px;
    width: auto;
    height: 550px;
  }
  .Contact .cs_contact img.bg-img {
    width: auto;
    height: 550px;
    top: 107px;
    left: 5px;
  }
  .Contact .cs_profile-boxes .profile-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .Contact .cs_profile-boxes .profile-box img.portrait {
    margin-bottom: 20px;
  }
  .Contact .mapped-wrapper {
    position: relative;
  }
  .Contact .mapped-wrapper .image-wrapper {
    position: absolute;
    width: 100%;
    z-index: 99;
    left: 0px;
    top: 10px;
    text-align: center;
  }
  .Contact .mapped-wrapper .image-wrapper img {
    width: 150px;
    max-width: 40%;
    margin: 5px;
    display: inline-block;
  }
}